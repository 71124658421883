import React, { useEffect, useState } from "react";
import { Select as MuiSelect, MenuItem, Box, IconButton, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import { Add as AddIcon, Close as RemoveIcon } from "@mui/icons-material";
import * as MuiIcons from '@mui/icons-material';
import { Label } from "../../../../components/FormV2/components";
import { HelperText } from "../../../../components/FormV2/components/HelperText";

const DynamicMuiIcon = ({ iconName }) => {
    if (!iconName) return null;
    const Icon = MuiIcons[iconName];
    return Icon ? <Icon sx={{ fontSize: 20 }} /> : null;
};

export const CustomSelect = ({
    name,
    label,
    control,
    defaultValue,
    full,
    options = [],
    customMuiIconName
}) => {
    const [customIcons, setCustomIcons] = useState({});
    const { field, fieldState: { error } } = useController({
        name,
        control,
        defaultValue: defaultValue ? {
            className: defaultValue?.properties?.className,
            iconUrl: defaultValue?.properties?.customIcon?.url || null
        } : null
    });

    const handleCustomIconAdd = (value) => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";
        fileInput.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const iconUrl = reader.result;
                    setCustomIcons((prev) => ({
                        ...prev,
                        [value]: iconUrl,
                    }));
                    field.onChange({
                        className: value,
                        iconUrl: iconUrl
                    });
                };
                reader.readAsDataURL(file);
            }
        };
        fileInput.click();
    };

    const handleCustomIconRemove = (value) => {
        setCustomIcons((prev) => {
            const updated = { ...prev };
            delete updated[value];
            return updated;
        });
        field.onChange({
            className: value,
            iconUrl: null
        });
    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        field.onChange({
            className: selectedValue,
            iconUrl: customMuiIconName ? null : customIcons[selectedValue] || null
        });
    };

    useEffect(() => {
        // Initialize customIcons with defaultValue if present
        if (defaultValue?.properties?.customIcon?.url) {
            setCustomIcons((prev) => ({
                ...prev,
                [defaultValue.properties.className]: defaultValue.properties.customIcon.url,
            }));
        }
    }, [defaultValue, customMuiIconName]);


    const renderIcon = (optionValue, optionIcon, isSelected = false) => {
        if (isSelected && customMuiIconName && MuiIcons[customMuiIconName]) {
            return <DynamicMuiIcon iconName={customMuiIconName} />;
        }

        if (customIcons[optionValue]) {
            return (
                <Box sx={{
                    width: 20,
                    height: 20,
                    borderRadius: 2,
                    overflow: 'hidden',
                }}>
                    <img
                        src={customIcons[optionValue]}
                        alt={`Custom icon`}
                        style={{
                            width: 20,
                            height: 20,
                        }}
                    />
                </Box>
            );
        }
        const DefaultIcon = optionIcon;
        return <DefaultIcon sx={{ fontSize: 20 }} />;
    };

    return (
        <Box sx={{
            width: full ? "100%" : "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
        }}>
            <Box>
                <Label>{label}</Label>
                <MuiSelect
                    {...field}
                    value={field.value?.className || ''}
                    onChange={handleSelectChange}
                    sx={error ? {
                        width: full ? "100%" : "auto",
                        maxWidth: "100%",
                        border: "#D82C0D 1px solid",
                        borderRadius: "4px",
                        bgcolor: "#FFF4F4",
                    } : {
                        width: full ? "100%" : "auto",
                        maxWidth: "100%"
                    }}
                    renderValue={(selected) => {
                        const selectedOption = options.find(option => option.value === selected);
                        if (!selectedOption) return '';

                        return (
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                            }}>
                                {renderIcon(selected, selectedOption.icon, true)} {/* Pass true for selected state */}
                                {selectedOption.label}
                            </Box>
                        );
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                                overflowY: "auto",
                            },
                        },
                    }}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                            }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    {renderIcon(option.value, option.icon, option.value === field.value?.className)}
                                    {option.label}
                                </Box>
                                <Box>
                                    {/* Only show add/remove buttons if this isn't the selected option with a custom MUI icon */}
                                    {!(option.value === field.value?.className && customMuiIconName) && (
                                        customIcons[option.value] ? (
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCustomIconRemove(option.value);
                                                }}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCustomIconAdd(option.value);
                                                }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        )
                                    )}
                                </Box>
                            </Box>
                        </MenuItem>
                    ))}
                </MuiSelect>
            </Box>

            {error && <HelperText message={error?.message} />}
        </Box>
    );
};