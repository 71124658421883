import { useParams } from 'react-router';
import { Heading } from '../../components';
import { Link } from 'react-router-dom';
import { ReactComponent as PageIcon } from '../../assets/icons/document.svg';
import './styles.scss';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

const StaticPagesList = () => {
  const { developmentId } = useParams();
  const { mortgageCalculatorMenu, dynamicPagesMenu } = useSelector((state) => state.optionalMenuItems);
  const { dynamicModules } = useSelector((state) => state.properties);
  const [dynamicModulesList, setDynamicModulesList] = useState([]);
  // pages will always be static
  const staticPages = [
    {
      title: 'welcome',
      path: 'welcome',
    },
    {
      title: 'home',
      path: 'home',
    },
    {
      title: 'contact',
      path: 'contact',
    },
    {
      title: 'register interest',
      path: 'register-interest',
    },
    {
      title: 'mortgage calculator',
      path: 'mortgage-calculator',
      show: mortgageCalculatorMenu,
    }
  ];
  // make dynamic pages
  const processDynamicPages = () => {
    const values = Object.values(dynamicModules);
    const objectsArray = values.map((module) => {
      return {
        title: module.title,
        path: module.id,
      }
    });
    setDynamicModulesList(objectsArray);
  };

  useEffect(() => {
    processDynamicPages();
  }, [dynamicModules]);

  return (
    <div className='page-padding static-pages'>
      {/* all static pages */}
      <div className='heading-with-button'>
        <Heading headingStyles={{ margin: 0 }} label='static pages' />
      </div>
      <div className='static-pages-list'>
        <p>
          Static pages are the less complicated pages of Signtouch and only
          require a single configuration of content. You can do that here by
          clicking into any of the pages below.
        </p>

        {staticPages.map(({ title, path, icon, show = true }, key) => {
          return (
            <React.Fragment key={key}>
            {show && <div
              className='static-page-link-container'
              key={`static-page-${title}`}
              >
              <Link to={`/developments/${developmentId}/pages/${path}`}>
                <div className='static-page-link'>
                  {icon ? icon : <PageIcon fill={'#1b1b1d'} />}
                  <p>{title}</p>
                </div>
              </Link>
            </div>}
            </React.Fragment>
          );
        })}
      </div>
      {/* all dynamic pages */}
      {dynamicPagesMenu && (
      <>
        <div className='heading-with-button' style={{ paddingTop: '2rem' }}>
          <Heading headingStyles={{ margin: 0 }} label='dynamic pages'  />
        </div>
        <div className='static-pages-list'  style={{ paddingBottom: '2rem' }}>
          <p>
            Dynamic pages are the less complicated pages of Signtouch. They can be create from modules. 
            You can do that here byclicking into any of the pages below.
          </p>

          {dynamicModulesList.map(({ title, path, icon, show = true }, key) => {
            return (
              <React.Fragment key={key}>
              {show && <div
                className='static-page-link-container'
                key={`static-page-${title}`}
                >
                <Link to={`/developments/${developmentId}/pages/dynamic-page/${path}`}>
                  <div className='static-page-link'>
                    {icon ? icon : <PageIcon fill={'#1b1b1d'} />}
                    <p>{title}</p>
                  </div>
                </Link>
              </div>}
              </React.Fragment>
            );
          })}
        </div>
      </>
      )}
    </div>
  );
};

export default StaticPagesList;
