import './styles.scss';
import store from '../../store';
import { deletePlot as dispatchDeletePlot } from '../../store/actions/plots';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addError, ApiError, beablooApi } from '../../utils';
import { delOption } from '../../store/actions/options';
import { deleteOptionType } from '../../store/actions/optionTypes';
import { deleteOptionCategory } from '../../store/actions/optionCategories';
import { deleteHouseType } from '../../store/actions/houseTypes';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { updateLocalInformation } from '../../store/actions/localInformation';
import { setLocalInformation } from '../../store/actions/localInformation';
import {
  deleteDesignAdviceArticle,
  deleteDesignAdviceType,
  setDesignAdvice,
} from '../../store/actions/designAdvice';
import { deletePromotion } from '../../store/actions/promotions-and-sales';
import { Button, Heading } from '..';
import { deleteDesignAdviceCategory } from '../../store/actions/designAdvice';

const DeleteModal = (props) => {
  const [load, setLoad] = useState(false);
  const { developmentId } = useParams();
  const dispatch = useDispatch();

  const [routeToDirect, setRoute] = useState('');
  const [objectName, setObjectName] = useState('');
  const [objectId, setObjectId] = useState('');

  useEffect(() => {
    switch (props.type) {
      case 'plots':
        setObjectName('plot ' + props.item.number);
        setObjectId(props.item.id);
        setRoute(
          `/developments/${developmentId}/plots/${props.item.id}/delete`,
        );
        break;
      case 'option':
        setObjectName('option');
        setObjectId(props.options.id);
        setRoute(
          `/developments/${developmentId}/options/${props.options.id}/delete`,
        );
        break;
      case 'option category':
        setObjectName('option category');
        setObjectId(props.options.id);
        setRoute(
          `/developments/${developmentId}/optionCategories/${props.options.id}/delete`,
        );
        break;
      case 'option type':
        setObjectName('option type');
        setObjectId(props.options.id);
        setRoute(
          `/developments/${developmentId}/optionTypes/${props.options.id}/delete`,
        );
        break;
      case 'house type':
        setObjectName('house type');
        setObjectId(props.house.id);
        setRoute(
          `/developments/${developmentId}/houseTypes/${props.house.id}/delete`,
        );
        break;
      case 'local information':
        setObjectName('local information');
        setObjectId(props.localInformation.id);
        setRoute(
          `/developments/${developmentId}/localInformation/${props.localInformation.id}/delete`,
        );
        break;
      case 'design type':
        setObjectName('design advice type');
        setObjectId(props.options.id);
        setRoute(
          `/developments/${developmentId}/designAdviceTypes/${props.designAdvice.id}/delete`,
        );
        break;
      case 'design category':
        setObjectName('design advice category');
        setObjectId(props.options.id);
        setRoute(
          `/developments/${developmentId}/designAdviceCategories/${props.designAdvice.id}/delete`,
        );
        break;
      case 'design advice':
        setObjectName('design advice');
        setObjectId(props.options.id);
        setRoute(
          `/developments/${developmentId}/designAdviceArticles/${props.designAdvice.id}/delete`,
        );
        break;
      case 'promotion':
        setObjectName('promotion');
        setObjectId(props.options.id);
        setRoute(
          `/developments/${developmentId}/promotions/${props.options.id}/delete`,
        );
        break;
      case 'dynamic-module':
        setObjectName(`module ${props.objectName}`);
        setObjectId(props.objectId);
        setRoute(
          `/developments/${developmentId}/properties/${props.objectId}/remove-dynamic-module`,
        )
        break;
      default:
        setObjectName('unknown');
        setRoute('');
        break;
    }
  }, []);

  const handleDelete = async (id) => {
    setLoad(true);
    const pendingId = addPending('Deleting...');
    await beablooApi({ method: 'DELETE', route: routeToDirect })
      .then((r) => {
        // request has finished remove pending
        removePending(pendingId);

        // request failed throw error
        if (!r.success) {
          throw new ApiError(r.message, r.errors);
        }

        switch (props.type) {
          case 'plots':
            dispatch(dispatchDeletePlot({ id: id }));
            break;

          case 'option':
            dispatch(delOption({ id: id }));
            break;

          case 'option type':
            dispatch(deleteOptionType({ id: id }));
            break;

          case 'option category':
            dispatch(deleteOptionCategory({ id: id }));
            break;

          case 'house type':
            dispatch(deleteHouseType({ id: id }));
            break;

          case 'local information':
            dispatch(setLocalInformation());
            break;

          case 'design advice':
            dispatch(deleteDesignAdviceArticle({ id: id }));
            break;

          case 'design type':
            dispatch(deleteDesignAdviceType({ id: id }));
            break;

          case 'design category':
            dispatch(deleteDesignAdviceCategory({ id: id }));
            break;

          case 'promotion':
            dispatch(deletePromotion(props.options));
            break;
          case 'dynamic-module': {
            dispatch({
              type: 'UPDATE_DYNAMIC_MODULE',
              data: {...r.data.data},
            })
            dispatch({
              type: 'REMOVE_DYNAMIC_MODULE',
              data: { id: props.objectId },
            })
            break;
          }
          default:
            break;
        }

        // remove dark background
        setLoad(false);
        handleClose();
      })
      .catch((e) => {
        handleClose();
        // handle the error by informing the user
        addError(e.message);
      });
  };

  const handleClose = () => {
    if (props.hasOwnProperty('closeModal')) {
      props.closeModal();
    }
  };

  // You can pass in in `objectName` as a prop to support more than just type.
  // Handle the onClose event in the parent for handling the closing
  return (
    <>
      {!load && (
        <div className='modal-container'>
          <div className='delete-modal'>
            <Heading
              label={`Delete ${
                props.objectName ? props.objectName : objectName
              }?`}
            />
            <div className='confirmation-text'>
              <p>
                {props.customText
                  ? props.customText
                  : `Are you sure you want to delete this ${
                      props.objectName ? props.objectName : objectName
                    }?`}
              </p>
              <p>
                Your changes will be reflected to others next time they load the
                development
              </p>
            </div>
            <div className='button-group'>
              <div onClick={() => handleDelete(objectId)}>
                <Button label={'delete'} color={'crimson'} />
              </div>
              <div onClick={() => handleClose()}>
                <Button label={'cancel'} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteModal;
