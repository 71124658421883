import schema from '../schemas/_streetView';

const dynamicPageReducers = (state = schema, action) => {
  switch (action.type) {
    case 'SET_DYNAMIC_PAGES':
      return {
        ...action.data,
      };
    case 'UPDATE_DYNAMIC_PAGES': {
      const withoutPage = state.data.filter((page) => page.id !== action.data.id);
      return {
        ...state,
        data: [...withoutPage, action.data],
      }
    }
    default:
      return {
        ...state,
      };
  }
};

export { dynamicPageReducers };
