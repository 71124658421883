import { useEffect } from 'react';
import { usePreviewProperties } from '../../contexts/brandingContext';
import { makeCssColor } from '../../../../utils';
import { QRCodeSVG } from 'qrcode.react';
import './styles.scss';
import { useSelector } from 'react-redux';

const PreviewDynamicPage = ({ content, codeHeading, codeUrl, codeText, coverMedia, mediaType, pageId }) => {
  // ask the preview to give us its properties
  const properties = usePreviewProperties();
  const { dynamicModules } = useSelector((state) => state.properties);
  const module = dynamicModules[`module_${pageId}`];
  useEffect(() => {}, [content, codeUrl, codeText, coverMedia, codeHeading]);
  const detectType = (input) => {
    if (typeof input === 'string') {
      return 'string';
    }
    if (typeof input === 'object') {
      if (Array.isArray(input)) {
        return 'array';
      }
      if (input !== null && !Array.isArray(input)) {
        return 'object';
      }
    }
    return 'unknown';
  }
  return (
    <>
      <style>
        span.highlight {`{ color: ${properties.branding.tertiary}}`}
      </style>
      <div
        className='preview-interest'
        style={{
          background: makeCssColor(properties.branding.section),
        }}
      >
        <div className='container'>
          <div className='flex-grid'>
            <div style={{ width: '100%' }}>
              <h1 style={{ textTransform: 'capitalize' }}>{module?.title || 'Page'}</h1>
              <div className='preview-media'>
                {(coverMedia && mediaType == 'image') &&
                  <img src={detectType(coverMedia) == 'string' ? coverMedia : coverMedia.url} alt="mortgage-calculator-media" />
                }
                {(coverMedia && mediaType == 'video') && 
                  <video src={detectType(coverMedia) == 'string' ? coverMedia : coverMedia.url} autoPlay muted loop />
                }
              </div>
              <hr style={{ borderColor: `${properties.branding.line}` }} />
              <p className='scroll-container' dangerouslySetInnerHTML={{ __html: content }} />
              <hr style={{ borderColor: `${properties.branding.line}` }} />
              <p dangerouslySetInnerHTML={{ __html: codeText }} />
              {codeUrl &&
                <div className='qr-code'>
                  <h4>{codeHeading}</h4>
                  <QRCodeSVG value={codeUrl} style={{ width: '6rem', height: '6rem' }}/>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewDynamicPage;
