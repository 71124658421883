import { ApiError, beablooApi } from '../../utils';
import { setDynamicPagesMenu, setMortgageCalculatorMenu } from './optionalMenuItems';

const setDynamicPages = () => {
  return async (dispatch, getState) => {
    const { general } = getState();
    const development = localStorage.getItem('signtouch-current-development');
    const { topMostLevel } = JSON.parse(development);
    const response = await beablooApi({
      method: 'GET',
      route: `/developments/${general.currentDevelopment.id}/dynamicPages?parent=${topMostLevel}`,
    })
      .then((r) => {
        if (r.success) {
          return r.data;
        } else {
          dispatch(setDynamicPagesMenu(false));
        }
        
      })
      .catch((e) => {
        console.log('error', e);
      });

    dispatch({
      type: 'SET_DYNAMIC_PAGES',
      data: response,
    });
  };
};

const updateDynamicPages = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_DYNAMIC_PAGES',
      data: data,
      });
  }
};

export { setDynamicPages, updateDynamicPages };
