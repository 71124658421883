import './styles.scss';  
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Form, Heading } from '..';
import { Close } from '@mui/icons-material';
import { addError, beablooApi } from '../../utils';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';

const AddDynamicModal = (props) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState([]);
  const { developmentId } = useParams();
  const dispatch = useDispatch();

  const handleSubmit = async (data) => {
    setSaving(true);
    const pending = addPending(`Adding dynamic module ${data.title}`);
    if (data.customIcon !== null  && data.hasOwnProperty('title')) {
      try {
        let payload = {dynamicModule: {
          ...data,
          enabled: data.hasOwnProperty('enabled') ? data.enabled : false,
        }};
        await beablooApi({
          method: 'PUT',
          route: `/developments/${developmentId}/properties/add-dynamic-module`,
          payload: payload,
        }).then((response) => {
          if (response.success) {
            dispatch({
              type: 'UPDATE_DYNAMIC_MODULE',
              data: response.data,
            });
          } else {
            addError(response.message);
          }
        })
      } catch (e) {
        console.log(e);
      } finally {
        removePending(pending);
        handleClose();
      }

    } else if (data.customIcon === null && !data.hasOwnProperty('title')) {
      addError('Please enter a title.');
      addError('Please upload an icon.');
    } else if (data.customIcon === null) {
      addError('Please upload an icon.');
    } else if (!data.hasOwnProperty('title')) {
      addError('Please enter a title.');
    }
    setSaving(false);
  };

  const handleClose = () => {
    if (props.hasOwnProperty('closeModal') && !saving) {
      props.closeModal();
    }
  };

  // You can pass in in `objectName` as a prop to support more than just type.
  // Handle the onClose event in the parent for handling the closing
  return (
    <>
      <div className='modal-container'>
        <div className='add-modal'>
          <div className='close'>
            <Close onClick={handleClose} style={{ fill: saving ? 'gray' : 'black' }}/>
          </div>
          <Heading
            label={'Add Dynamic Module'}
          />
          <div className='confirmation-text'>
            <p>
              Fill in the details below to add a new dynamic module.
            </p>
            <Form
              inputs={[
                {
                  name: 'title',
                  label: 'Title',
                  type: 'text',
                },
                {
                  name: 'customIcon',
                  label: 'Icon',
                  type: 'single-image',
                },
                {
                  name: 'enabled',
                  label: 'Enabled',
                  type: 'switch',
                }
              ]}
              onSubmit={(e) => handleSubmit(e)}
              errors={error}
              canSubmit={!saving}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDynamicModal;
