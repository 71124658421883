import { Box, TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import { Label } from '../Label';
import { HelperText } from '../HelperText';

export const TextInput = ({ name, label, control, defaultValue, full, placeholder = '' }) => {
  const { field, fieldState: { error } } = useController({ name, control, defaultValue });

  return (
    <Box 
      sx={{ 
        width: full ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Box>
        <Label>{ label }</Label>
        <TextField 
          {...field}
          placeholder={placeholder}
          sx={error
            ? { width: full ? '100%' : 'auto', border: '#D82C0D 1px solid', borderRadius: '4px', bgcolor: '#FFF4F4' }
            : { width: full ? '100%' : 'auto' }
          }
        />
      </Box>
      {error && (
        <HelperText message={error?.message} />
      )}
    </Box>
  );
};
