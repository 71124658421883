import { Button, DeleteModal, Heading } from '../../components';
import { Module } from './Module';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  useSensors,
  useSensor,
  PointerSensor,
  DndContext,
} from '@dnd-kit/core';
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { beablooApi, addError, addNotification } from '../../utils';
import { useParams } from 'react-router-dom';
import './index.scss';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import AddDynamicModal from '../../components/AddDynamicModuleModal';

const Modules = (props) => {
  const { developmentId } = useParams();

  const [saving, setSaving] = useState(false);

  // keep track of request status (delete)
  const [isPendingRequest, setIsPendingRequest] = useState(false);
  const [modalData, setModalData] = useState({});
  const toggleIsPendingRequest = (data) => {
    setModalData((state)=> {
      return data;
    });
    setIsPendingRequest((state) => {
      return !state;
    });
  }
  // request of adding a new dynamic module
  const [addDynamicModal, setAddDynamicModal] = useState(false);
  const toggleAddDynamicModules = (data) => {
    setAddDynamicModal((state) => {
      return !state;
    });
  }

  // Get the current modules from state
  const { modules, houseModules, dynamicModules } = useSelector((state) => state.properties);
  // Define some custom sensors for DNDKit, means we can hopefully use components inside the sortable
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  // Keep a copy of the modified modules here for sending to the api on saving.
  const [loadedModules, setLoadedModules] = useState([]);
  const [loadedHouseModules, setHouseModules] = useState([]);
  const [loadedDynamicModules, setDynamicModules] = useState([]);

  // When the page loads convert the object into an array
  useEffect(() => {
    // Create an array
    const modulesList = [];
    const houseModulesList = [];
    const dynamicModulesList = [];
    // Got through all children properties in the modules object
    for (const [key, value] of Object.entries(modules)) {
      // Make a tempory item with the module properties
      let tempItem = value;
      // Set the id of the module to be its internal id e.g wishList
      tempItem.id = key;
      // Add the module to the array so we can put it in state
      modulesList.push(tempItem);
    }

    for (const [key, value] of Object.entries(houseModules)) {
      // Make a tempory item with the module properties
      let tempItem = value;
      // Set the id of the module to be its internal id e.g wishList
      tempItem.id = key;
      // Add the module to the array so we can put it in state
      houseModulesList.push(tempItem);
    }

    for (const [key, value] of Object.entries(dynamicModules)) {
      // Make a tempory item with the module properties
      let tempItem = value;
      // Set the id of the module to be its internal id e.g wishList
      tempItem.id = value.id;
      // Add the module to the array so we can put it in state
      dynamicModulesList.push(tempItem);
    }

    setHouseModules(houseModulesList.sort((a, b) => a.weight - b.weight));
    // Put the newly created array in state
    setLoadedModules(modulesList.sort((a, b) => a.weight - b.weight));
    // Put the newly created array in state
    setDynamicModules(dynamicModulesList.sort((a, b) => a.weight - b.weight));
  }, [modules, dynamicModules]);

  // This function runs when a user drops a module.
  const handleDragEnd = (e) => {
    // Get the two realted modules
    const { active, over } = e;
    // Check it actually needs to swap.
    if (active.id !== over.id) {
      // Modify the state to the new order
      setLoadedModules((modules) => {
        // Find the positions in the array of loaded moduels - we only get Id's
        const oldIndex = modules.indexOf(
          modules.find((m) => m.id === active.id),
        );
        const newIndex = modules.indexOf(modules.find((m) => m.id === over.id));
        // Use a dndkit function to swap them round and return to set state
        return arrayMove(modules, oldIndex, newIndex);
      });
    }
  };

  // This function runs when a user drops a house module.
  const handleModulesDragEnd = (e) => {
    // Get the two realted modules
    const { active, over } = e;
    // Check it actually needs to swap.
    if (active.id !== over.id) {
      // Modify the state to the new order
      setHouseModules((modules) => {
        // Find the positions in the array of loaded moduels - we only get Id's
        const oldIndex = modules.indexOf(
          modules.find((m) => m.id === active.id),
        );
        const newIndex = modules.indexOf(modules.find((m) => m.id === over.id));
        // Use a dndkit function to swap them round and return to set state
        return arrayMove(modules, oldIndex, newIndex);
      });
    }
  };

  const handleDynamicModulesDragEnd = (e) => {
    // Get the two realted modules
    const { active, over } = e;
    // Check it actually needs to swap.
    if (active.id !== over.id) {
      // Modify the state to the new order
      setDynamicModules((modules) => {
        // Find the positions in the array of loaded moduels - we only get Id's
        const oldIndex = modules.indexOf(
          modules.find((m) => m.id === active.id),
        );
        const newIndex = modules.indexOf(modules.find((m) => m.id === over.id));
        // Use a dndkit function to swap them round and return to set state
        return arrayMove(modules, oldIndex, newIndex);
      });
  };
  }

  const handleSubmit = async() => {
    // Stops us saving multiple times
    if (saving) return;

    let pendingId = addPending('Saving...');

    setSaving(true);

    // Creates a payload for us to send to the api
    let modulesPayload = {};
    // Goes through the array and turns it back into an object with keys and weight
    for (let i = 0; i < loadedModules.length; i++) {
      // Add the correct weight back into the module
      loadedModules[i].weight = i + 1;
      // Add the module cofig to the payload object by id
      modulesPayload[loadedModules[i].id] = loadedModules[i];
    }

    let houseModulesPayload = {};

    for (let i = 0; i < loadedHouseModules.length; i++) {
      // Add the correct weight back into the module
      loadedHouseModules[i].weight = i + 1;
      // Add the module cofig to the payload object by id
      houseModulesPayload[loadedHouseModules[i].id] = loadedHouseModules[i];
    }

    // TODO: Needs to complete update redux
    Promise.all([
      await beablooApi({
        method: 'PUT',
        route: `/developments/${developmentId}/properties/update`,
        payload: { modules: modulesPayload, houseModules: houseModulesPayload },
      }),
      await beablooApi({
        method: 'PUT',
        route: `/developments/${developmentId}/properties/update-dynamic-module`,
        payload: { dynamicModules: loadedDynamicModules },
      }),
    ]).then(([res1, res2]) => {
      if (!res1.success || !res2.success) {
        addError('There was an error updating the properties.');
        return;
      }
      // dispatch(setProperties({properties, ...colors}))
      addNotification('Successfully updated the properties.');
    }).catch((e)=>{
      addError('There was an error updating the properties.');
    }).finally(() => {
      removePending(pendingId);
      setSaving(false);
    });
    
  };

  // Used to toggle modules as they're nestred
  const toggleModule = (e, state) => {
    // find the index of the module we want
    const oldIndex = loadedModules.indexOf(
      loadedModules.find((m) => m.id === e),
    );
    // Start updating the state
    setLoadedModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].enabled = state;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  // Used to toggle modules as they're nestred
  const toggleHouseModules = (e, state) => {
    // find the index of the module we want
    const oldIndex = loadedHouseModules.indexOf(
      loadedHouseModules.find((m) => m.id === e),
    );

    // Start updating the state
    setHouseModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].enabled = state;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  const toggleDynamicModules = (e, state) => {
    // find the index of the module we want
    const oldIndex = loadedDynamicModules.indexOf(
      loadedDynamicModules.find((m) => m.id === e),
    );

    // Start updating the state
    setDynamicModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].enabled = state;
      // Return the newly modified array
      return moduilesCopy;
    })
  };

  const setIcon = (e, newIcon) => {
    const oldIndex = loadedModules.indexOf(
      loadedModules.find((m) => m.id === e),
    );
    // Start updating the state
    setLoadedModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].customIcon = newIcon ? newIcon : false;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  const setTitle = (e, newTitle) => {
    const oldIndex = loadedModules.indexOf(
      loadedModules.find((m) => m.id === e),
    );
    // Start updating the state
    setLoadedModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].title = newTitle;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  const setDynamicModulesIcon = (e, newIcon) => {
    const oldIndex = loadedDynamicModules.indexOf(
      loadedDynamicModules.find((m) => m.id === e),
    );
    // Start updating the state
    setDynamicModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].customIcon = newIcon ? newIcon : false;
      // Return the newly modified array
      return moduilesCopy;
    });
  };

  const setDynamicModulesTitle = (e, newTitle) => {
    const oldIndex = loadedDynamicModules.indexOf(
      loadedDynamicModules.find((m) => m.id === e),
    );
    // Start updating the state
    setDynamicModules((modules) => {
      // Copy the state as it's immutable.
      let moduilesCopy = modules;
      // Set the state of enabled to whatever the child said using the index from before.
      moduilesCopy[oldIndex].title = newTitle;
      // Return the newly modified array
      return moduilesCopy;
    });
  }

  return (
    <div className={'page-padding modules'}>
      <div className='modules'>
        <Heading label={'Configure Modules'} />
        <p>
          You can enable or disable modules on this page. Disabling a module
          prevents it from being used on any totem within the organisation. You
          can also drag the modules to re-order how they appear in the menu.
        </p>

        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <SortableContext
            items={loadedModules}
            strategy={verticalListSortingStrategy}
          >
            {loadedModules.map((mod) => {
              return (
                <Module
                  canExpand
                  key={mod.id}
                  itemName={mod.title}
                  itemKey={mod.id}
                  {...mod}
                  setIcon={setIcon}
                  setTitle={setTitle}
                  disableModule={toggleModule}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <br />
        <div className='heading-with-button'>
          <Heading label={'Configure Dynamic Modules'} />
          <Button label={'Add'} onClick={toggleAddDynamicModules}/>
        </div>
        <p>
          You can add, delete annd enable or disable dynamicModules modules on this page. Disabling a module
          prevents it from being used on any totem within the organisation. You
          can also drag the modules to re-order how they appear in the menu.
        </p>
        <DndContext sensors={sensors} onDragEnd={handleDynamicModulesDragEnd}>
          <SortableContext
            items={loadedDynamicModules}
            strategy={verticalListSortingStrategy}
          >
            {loadedDynamicModules.map((mod) => {
              return (
                <Module
                  canExpand
                  key={mod.id}
                  itemName={mod.id}
                  itemKey={mod.id}
                  useTitleAsDisplay={true}
                  isDeleteable={true}
                  toggleDelete={toggleIsPendingRequest}
                  {...mod}
                  setIcon={setDynamicModulesIcon}
                  setTitle={setDynamicModulesTitle}
                  disableModule={toggleDynamicModules}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <br />
        <Heading label={'Configure House Modules'} />
        <p>
          House modules are optional extra content shown on plots and house
          types pages. You can enable or re-order them here.
        </p>
        <DndContext sensors={sensors} onDragEnd={handleModulesDragEnd}>
          <SortableContext
            items={loadedHouseModules}
            strategy={verticalListSortingStrategy}
          >
            {loadedHouseModules.map((mod) => {
              return (
                <Module
                  key={mod.id}
                  itemName={mod.id}
                  itemKey={mod.id}
                  customIcon={mod.customIcon ? mod.customIcon : null}
                  {...mod}
                  disableModule={toggleHouseModules}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        <button
          className={'button button-primary'}
          onClick={(e) => handleSubmit()}
        >
          Save
        </button>
      </div>
      {isPendingRequest && (
        <DeleteModal
          type='dynamic-module'
          objectName={modalData.title ?? 'Module'}
          objectId={modalData.id}
          closeModal={toggleIsPendingRequest}
        />
      )}
      {addDynamicModal && (
        <AddDynamicModal
          closeModal={toggleAddDynamicModules}
        />
      )}
    </div>
  );
};

export default Modules;
