import { Button, Heading } from '../../components';
import { useSelector } from 'react-redux';
import { TreeItem } from '../../components';
import { useNavigate } from 'react-router-dom';
import addPending from '../../utils/addPending';
import removePending from '../../utils/removePending';
import { addError } from '../../utils';
import { addNotification } from '../../utils';
import { useDispatch } from 'react-redux';
import { setLocalInformation } from '../../store/actions/localInformation';
import { FeatureModal, LocalInformationModal } from './components';
import { useQuery } from '../../hooks';
import { localInformationService } from '../../lib/signtouch';
import './style.scss';

const LocalInformation = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const dispatch = useDispatch();
  
  const showLocalInformationModal = query.get('selectedInformation');
  const showFeatureModal = query.get('selectedFeature') && query.get('informationOrigin');

  const localInformation = useSelector((state) => state.localInformation);

  const handleDeleteLocalInformation = async id => {
    const pendingToast = addPending('Deleting local information');
    try {
      await localInformationService.deleteLocalInformation(id);
      await dispatch(setLocalInformation());
      removePending(pendingToast);
      addNotification('Successfully deleted local information');
    } catch {
      removePending(pendingToast);
      addError('Unable to delete local information');
    }
  }

  const handleDeleteFeature = async (title, informationEntryId) => {
    const pendingToast = addPending('Deleting feature');
    try {
      await localInformationService.deleteLocalInformationFeature(title, informationEntryId);
      await dispatch(setLocalInformation());
      removePending(pendingToast);
      addNotification('Successfully deleted feature');
    } catch {
      removePending(pendingToast);
      addError('Unable to delete feature');
    }
  }

  return (
    <>
      {showLocalInformationModal && (
        <LocalInformationModal open={showLocalInformationModal} />
      )}
      {showFeatureModal && (
        <FeatureModal open={showFeatureModal} />
      )}

      <div className={'page-padding local-information'}>

        <div className='heading-with-button'>
          <Heading label='Local Information' />
          <div>
            <Button
              label={'create local information'}
              onClick={() => navigate('?selectedInformation=null')}
            />
          </div>
        </div>

        <div className='local-information-content'>
          {localInformation?.content?.map(info => {
            return (
              <TreeItem
                key={info.id}
                title={info.name}
                editIcon
                editLink={'#'}
                editEvent={() => navigate(`?selectedInformation=${info.id}`)}
                addIcon
                addLink={'#'}
                addEvent={() => navigate(`?informationOrigin=${info.id}&selectedFeature=null`)}
                deleteIcon
                deleteEvent={() => handleDeleteLocalInformation(info.id)}
              >
                {info?.features?.map(feat => {
                  return (
                    <TreeItem
                      key={feat?.id}
                      lastChild
                      title={feat?.properties?.title}
                      editIcon
                      editLink={'#'}
                      editEvent={() => navigate(`?informationOrigin=${info?.id}&selectedFeature=${encodeURIComponent(feat?.properties?.title)}`)}
                      deleteIcon
                      deleteEvent={() => handleDeleteFeature(feat.properties?.title, info.id)}
                    />
                  );
                })}
              </TreeItem>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LocalInformation;
