import { Box, Typography } from "@mui/material";
import { Label } from "../../../components/FormV2/components";
import { Heading } from "../../../components";
import { StatusBox } from "./StatusBox";
import { useDroppable } from "@dnd-kit/core";
import React from "react";
import styled from "styled-components";

export const StatusContainer = ({ heading, status, ...props }) => {
  const droppableId = `${heading}-container`;
  const { setNodeRef } = useDroppable({
    id: droppableId
  });
  return (
    <Box backgroundColor={'#e8e8e8'} padding={'1rem'} borderRadius={'0.4rem'} height={'100%'}>
      <Heading label={heading} headingStyles={{ fontWeight: '700', fontSize: '20px' }} />
      {props.hasOwnProperty('description') && (
        <Description>{props.description}</Description>
      )}
      <Box ref={setNodeRef} id={droppableId} backgroundColor={'#d9d9d9'} borderRadius={'0.4rem'} padding={'0.5rem'} minHeight={'calc(100% - 3.5rem)'}>
        {status.map((item, index) => 
          <StatusBox key={index} status={item} />
        )}
      </Box>
    </Box>
  )
};

const Description = styled(`div`) ({
  marginBottom: '8px',
})