import { AccountBalance, LocalBar, LocalCafe, LocalHospital, LocalMall, Movie, Nightlife, Palette, Restaurant, School, ShoppingCart, Sports, SportsMartialArts, Theaters, WbTwilight } from "@mui/icons-material";

export const ICON_OPTIONS = [
  { label: 'Medical', value: 'medical' , icon: LocalHospital},
  { label: 'Council', value: 'council' , icon: AccountBalance},
  { label: 'Food & Drink', value: 'food', icon: Restaurant},
  { label: 'School', value: 'school' , icon: School},
  { label: 'Sport', value: 'sport' , icon: Sports},
  { label: 'Emergency', value: 'emergency' , icon: WbTwilight},
  { label: 'Shopping', value: 'shopping' , icon: LocalMall},
  { label: 'Nightlife', value: 'night' , icon: LocalBar},
  {label:'cafe', value:'cafe', icon: LocalCafe},
  { label: 'Theatre', value: 'theatre' , icon: Theaters},
  { label: 'Arts', value: 'arts' , icon: Palette},
];

