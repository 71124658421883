import { ApiError, beablooApi } from '../../utils';

const setHouseViewMenu = (data) => {
  return {
    type: 'SET_HOUSE_VIEW_MENU',
    data: data,
  }
};

const setMortgageCalculatorMenu = (data) => {
  return {
    type: 'SET_MORTGAGE_CALCULATOR_MENU',
    data: data,
  }
};

const setStreetViewMenu = (data) => {
  return {
    type: 'SET_STREET_VIEW_MENU',
    data: data,
  }
};

const setDynamicPagesMenu = (data) => {
  return {
    type: 'SET_DYNAMIC_PAGES',
    data: data,
  }
};

const resetMenuItems = () => {
  return {
    type: 'RESET_MENU_ITEMS',
    data: '',
  }
};

export { 
  setHouseViewMenu,
  setMortgageCalculatorMenu,
  setDynamicPagesMenu,
  setStreetViewMenu,
  resetMenuItems,
};
