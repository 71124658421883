import { Box, Grid } from "@mui/material";
import { Heading, SubHeading } from "../../components";
import { StatusContainer } from "./views/StatusContainer";
import { useBranding } from "../../hooks";
import { useEffect, useState } from "react";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { Button } from "../../components/FormV2/components";
import addPending from "../../utils/addPending";
import removePending from "../../utils/removePending";
import { addError, addNotification, beablooApi } from "../../utils";
import { setProperties } from "../../store/actions/properties";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export const StatusCategorisation = () => {
  const { developmentId } = useParams();
  const [ branding ] = useBranding();
  const { map } = branding;
  const properties = useSelector((state) => state.properties);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();
  // keys of status and container names should always be same
  const [status, setStatus] = useState({
    available: [],
    sold: [],
    not_applicable: [],
  });
  /*
    ** be sure to use consistent naming conventions
    ** name of key should be with underscore if more than one word
  */
  const [containers, setContainers] = useState({
    available: 'Available',
    sold: 'Sold',
    not_applicable: 'Not Applicable',
  });
  // this funtion will categorise the status into available and sold
  const statusCatagories = () => {
    const keys = Object.keys(map);
    const status_keys = keys.filter(key => !key.includes('label') && !key.includes('category'));
    // store for each status make new in case of new category
    let available = [];
    let sold = [];
    let not_applicable = [];
    // this map will itrate over each status in branding
    status_keys.map(status => {
      if (typeof map[`${status}category`] === 'number') {
        if (map[`${status}category`] === 0) {
          available.push({label: map[`${status}label`], color: map[status], key: status});
        } else if (map[`${status}category`] === 1) {
          sold.push({label: map[`${status}label`], color: map[status], key: status});
        } else if (map[`${status}category`] === 2) {
          not_applicable.push({label: map[`${status}label`], color: map[status], key: status});
        }
      } else if (!map[`${status}category`]) {
        // if category is not defined then it will categorise based on label
        if (map[`${status}label`].toLowerCase().includes('available')) {
          available.push({label: map[`${status}label`], color: map[status], key: status});
        } else if (map[`${status}label`].toLowerCase().includes('sold') || map[`${status}label`].toLowerCase().includes('reserved')) {
          sold.push({label: map[`${status}label`], color: map[status], key: status});
        } else {
          not_applicable.push({label: map[`${status}label`], color: map[status], key: status});
        }
      }
    });
    setStatus({
      not_applicable,
      available,
      sold,
    });
  }

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const statusKeys = Object.keys(status);
    let getSourceContainer = '';
    statusKeys.forEach(key => {
      if (status[key].find(status => status.label === active.id)) {
        getSourceContainer = key;
      }
    });
    const destination = over.id.split('-')[0];
    const removeSpace = destination.replace(/\s/g, '_');
    const destinationContainer = statusKeys.find(key => key === removeSpace.toLowerCase());
    if (getSourceContainer === destinationContainer) {
      return;
    }
    if (getSourceContainer && destinationContainer) {
      const updatedStatus = status[getSourceContainer].filter(status => status.label !== active.id);
      const updatedDestinationStatus = [...status[destinationContainer], status[getSourceContainer].find(status => status.label === active.id)];
      setStatus({
        ...status,
        [getSourceContainer]: updatedStatus,
        [destinationContainer]: updatedDestinationStatus,
      });
    }
  }

  const handleSubmit = () => {
    setSaving(true);
    // assign category to each status
    status.available.map(status => {
      map[`${status.key}category`] = 0;
    });
    status.sold.map(status => {
      map[`${status.key}category`] = 1;
    });
    status.not_applicable.map(status => {
      map[`${status.key}category`] = 2;
    });

    const pendingId = addPending('Saving Status Categories');

    beablooApi({
      method: 'PUT',
      route: `/developments/${developmentId}/properties/update`,
      payload: { branding: {map: map} },
    }).then((result) => {
      removePending(pendingId);
      
      if (!result.success) {
        addError('There was an error updating the Status Categories.');
        return;
      }
      dispatch(setProperties({ properties, ...result.data }));      
      setSaving(false);
      addNotification('Successfully updated properties.');
    });
  }
  
  useEffect(() => {
    statusCatagories();
  }, [branding]);

  return (
    <div className='page-padding'>
      <div className='heading-with-button'>
        <Heading label='status categorisation' />
        <Button variant="contained" onClick={handleSubmit}>Save</Button>
      </div>
      <SubHeading>
        Here you can drag and drop the status to categorise them into available / sold.
      </SubHeading>
      <Grid container spacing={2}>
        <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
          <Grid item xs={4}>
            <StatusContainer heading={containers.available} status={status.available} description={`Statuses in this category will effect count of house during filtration`} />
          </Grid>
          <Grid item xs={4}>
            <StatusContainer heading={containers.sold} status={status.sold} description={`Status in this category will hide price of plots from player app`}/>
          </Grid>
          <Grid item xs={4}>
            <StatusContainer heading={containers.not_applicable} status={status.not_applicable} description={`Status in this category will effect anything`}/>
          </Grid>
        </DndContext>
      </Grid>
    </div>
  )
};

