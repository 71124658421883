import initialState from '../schemas/_properties';

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROPERTIES':
      return {
        ...action.data,
      };
    case 'UPDATE_DYNAMIC_MODULE': {
      return {
        ...state,
        dynamicModules: {
          ...action.data.dynamicModules
        },
      };
    }

    case 'REMOVE_DYNAMIC_MODULE': {
      const { dynamicModules } = state;
      const { id } = action.data;
      const values = Object.values(dynamicModules);
      const newDynamicModules = values.filter((module) => module.id !== id);
      return {
        ...state,
        dynamicModules: newDynamicModules,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default propertiesReducer;
