import { clearPlots, setPlots } from './plots';
import { setGalleries } from './galleries';
import {
  applicationLoadError,
  applicationReady,
  setDevelopmentMetaData,
} from './general';
import { setHouseTypes } from './houseTypes';
import { setOptionCategories } from './optionCategories';
import { setOptions } from './options';
import { setOptionTypes } from './optionTypes';
import { setProperties } from './properties';
import { setWelcome } from './welcome';
import { setHome } from './home';
import { setContactUs } from './contact';
import { setIntrested } from './register-interest';
import { setTransportLinks } from './transportLinks';
import { setPromotionsAndSalesMethods } from './promotions-and-sales';
import { setLocalInformation } from './localInformation';
import { setDesignAdvice } from './designAdvice';
import { setPlayerUI } from './playerUI';
import { addError } from '../../utils';
import { requestMultiFloor } from './multiFloor';
import { setHouseView } from './houseView';
import { setMortgageCalculator } from './mortgage-calculator';
import  { setIntegrations } from './integrations';
import  { getStreetPins, getEnvironment } from './streetView';
import  { setDynamicPages } from './dynamicPages';

const initialise = () => {
  return async (dispatch, getState) => {
    // find the requested development
    const currentDevelopment = getState().general.currentDevelopment;

    // actions for all development types
    const dataToFetch = [
      { name: 'properties', action: setProperties }, // added
      { name: 'house types', action: setHouseTypes }, //
      { name: 'option categories', action: setOptionCategories }, //
      { name: 'option types', action: setOptionTypes },
      { name: 'options', action: setOptions }, //
      { name: 'galleries', action: setGalleries },
    ];

    // actions for child only developments
    const siteDataToFetch = [
      { name: 'plots', action: setPlots }, //
      { name: 'metadata', action: setDevelopmentMetaData },
      { name: 'welcome', action: setWelcome },
      { name: 'home', action: setHome },
      { name: 'contact', action: setContactUs },
      { name: 'register interest', action: setIntrested },
      { name: 'mortrage calculator', action: setMortgageCalculator },
      { name: 'transport links', action: setTransportLinks },
      { name: 'local information', action: setLocalInformation },
      { name: 'floors', action: requestMultiFloor },
      { name: 'houseView', action: setHouseView },
      { name: 'integrations', action: setIntegrations },
      { name: 'streetPins', action: getStreetPins },
      { name: 'enviornmentConfig', action: getEnvironment },
      { name: 'dynamic pages', action: setDynamicPages },
    ];

    const regionDataToFetch = [
      { name: 'promotions', action: setPromotionsAndSalesMethods },
      { name: 'playerUI', action: setPlayerUI },
    ];

    // actions for parent only developments
    const parentDataToFetch = [
      { name: 'promotions', action: setPromotionsAndSalesMethods },
      { name: 'design advice', action: setDesignAdvice },
    ];

    // decide what data needs to be fetched to load the requested development
    let totalDataToFetch;
    switch (currentDevelopment.type) {
      case 'signtouch-parent':
        totalDataToFetch = [...dataToFetch, ...parentDataToFetch];
        break;

      case 'signtouch-region':
        totalDataToFetch = [...regionDataToFetch, ...dataToFetch];
        break;

      case 'signtouch-site':
        totalDataToFetch = [...dataToFetch, ...siteDataToFetch];
        break;

      default:
        totalDataToFetch = dataToFetch;
        break;
    }

    // really shouldn't be using map but foreach wont comply lol
    try {
      await Promise.all(
        totalDataToFetch.map(async (request) => {
          await dispatch(request.action());
          return '';
        }),
      );
    } catch (e) {
      // something when wrong when loading the application. Throw an error
      console.log(e);
      addError(
        'Something went wrong loading the application. please try again',
      );
      dispatch(applicationLoadError());
    }

    // don't cram up the state with hundreds of plots if we don't need too
    if (currentDevelopment.type !== 'signtouch-site') {
      dispatch(clearPlots());
    }

    // tell state we're ready to go
    dispatch(applicationReady());

    // // handle dispatching action reduxn
    // const handleDispatch = async ({ name, action }) => {
    //   console.log('dispatch', name)
    //   await dispatch(action())
    //   return;
    // }

    // // using the async library to manage dispatching async actions to redux
    // async.each(totalDataToFetch, handleDispatch)
    //   .then(() => {
    //     console.log('LOADING FINISHED')

    //     // don't cram up the state with hundreds of plots if we don't need too
    //     if (currentDevelopment.type !== 'signtouch-site') {
    //       dispatch(clearPlots());
    //     }

    //     // tell state we're ready to go
    //     dispatch(applicationReady())
    //   })
    //   .catch(e => {
    //     // something when wrong when loading the application. Throw an error
    //     addError('Something went wrong loading the application. please try again')
    //     dispatch(applicationLoadError())
    //     console.log(e)
    //   })
  };
};

export { initialise };
